import React, { useEffect } from 'react';
import { useState } from 'react';
import MoneyLover from './Pages/MoneyLover';
import MoneyLoverEdit from './Pages/MoneyLoverEdit';
import MoneyAnalystic from './Pages/MoneyAnalystic';
import StudentManagement from './Pages/StudentManagement';
import ClassManagement from './Pages/ClassManagement';
import ClassForm from './Pages/ClassForm';
import Calendar from './Pages/Calendar';
import StudentForm from './Pages/StudentForm';
import NewClassStudent from './Pages/NewClassStudent';
import GoldPrice from './Pages/GoldPrice';
import TestFcm from './Pages/TestFcm';
import Login from './Pages/Login';
import Profile from './Pages/Profile';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBug } from '@fortawesome/free-solid-svg-icons';

import { getFirebaseToken, onForegroundMessage, onMessageListener, requestPermission } from './firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import { BE_API_URL } from './constants';

const menuItems = [
  { url: '/', title: 'Home' },
  { url: '/money-lover-analytic', title: 'Analyst' },
  { url: '/gold-price', title: 'Gold Price' },
  { url: '/profile', title: 'My Profile' },
  // { url: '/calendar', title: 'Calendar' },
  // { url: '/student-management', title: 'Students ' },
  // { url: '/class-management', title: 'Classes' },
]


function App() {
  // const [activeMenu, setActiveMenu] = useState('/');
  const [showMenu, setShowMenu] = useState(false);
  const [currentFcmtoken, setCurrentFcmtoken] = useState(null)
  const pathname = window.location.pathname
  const [isLoginPage, setIsLoginPage] = useState(pathname.includes('/login'))

  // const handleMenuClick = (url) => {
  //   setActiveMenu(url);
  // };

  // const handleGetFirebaseToken = () => {
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       console.log('Firebase token: ', firebaseToken);
  //       if (firebaseToken) {
  //         setCurrentFcmtoken(firebaseToken)
  //       }
  //     })
  //     .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
  // }

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  )

  const createFcmToken = (token) => {
    fetch(`${BE_API_URL}/api/v1/notifications/createFcmToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ fcmToken: token })
    })
      .then(response => response.json())
      .then(data => {
        console.info('Update fcm token successful!')
      })
      .catch(error => {
        console.error(error);
      });
  }

  const showNotification = (title, body) => {
    return toast.info(<ToastifyNotification title={title} body={body} />, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  useEffect(() => {
    console.log('isLoginPage', isLoginPage)
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (!isLoginPage && (!accessToken || !refreshToken)) {
      window.location.href = '/login';
    } else if (accessToken && refreshToken) {
      setIsLoginPage(false);
    }
    const fcmToken = localStorage.getItem("fcmToken");
    if (fcmToken) {
      console.log('FCM Token:', fcmToken)
      onForegroundMessage()
        .then((payload) => {
          console.log('Received foreground message: ', payload);
          const { notification: { title, body } } = payload;
          showNotification(title, body);
        })
        .catch(err => console.log('An error occured while retrieving foreground message. ', err));
      
      onMessageListener().then((payload) => {
          console.log('Message received. ', payload);
          const { title, body, icon } = payload.notification;
          showNotification(title, body);
          new Notification(title, { body, icon });
        }).catch((err) => console.log('Failed to receive message:', err));
    } else {
      // handleGetFirebaseToken()
      requestPermission().then((token) => {
        // Send the token to your server
        console.log('FCM Token:', token);
        createFcmToken(token)
      }).catch((err) => {
        console.error('Error during permission request or token retrieval:', err);
      });
    }
  }, [currentFcmtoken, isLoginPage]);

  const renderHeader = () => {
    return (
      <div className="w-full">
        <nav class="bg-blue-600 p-4">
          <div class="container mx-auto">
            <div class="flex items-center justify-between">
              <Link className='text-white text-lg font-bold' to="/">Ginger App ❤️</Link>
              <div class="lg:hidden">
                <button onClick={() => setShowMenu(!showMenu)} id="menu-toggle" class="text-white focus:outline-none">
                  <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                  </svg>
                </button>
              </div>
              <div class={`${showMenu ? 'block' : 'hidden'}`} id="mobile-menu">
                <div class="flex flex-col space-y-4 mt-4">
                  {menuItems.map((item, index) => {
                    return (
                      <Link onClick={() => setShowMenu(false)} key={index} className='text-white' to={item.url}>{item.title}</Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <div className='flex flex-col'>
          { !isLoginPage && renderHeader()}
          <div className="w-ful">
            <Routes>
              <Route path="/" element={<MoneyLover />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/gold-price" element={<GoldPrice />} />
              <Route path="/money-lover" element={<MoneyLover />} />
              <Route path="/money-lover-edit" element={<MoneyLoverEdit />} />
              <Route path="/money-lover-analytic" element={<MoneyAnalystic />} />
              <Route path="/student-management" element={<StudentManagement />} />
              <Route path="/class-management" element={<ClassManagement />} />
              <Route path="/classes/new" element={<ClassForm isEdit={false} />} />
              <Route path="/classes/:id" element={<ClassForm isEdit={true} />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/students/new" element={<StudentForm isEdit={false} />} />
              <Route path="/students/:id/edit" element={<StudentForm isEdit={true} />} />
              <Route path="/classes/:id/students" element={<NewClassStudent />} />
              <Route path='/test-fcm' element={<TestFcm />} />
            </Routes>
          </div>
        </div>
      </Router>
      <ToastContainer hideProgressBar />
    </LocalizationProvider>
  );
}

export default App;
