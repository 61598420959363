import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from 'react-router-dom';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrash, faEye, faPlus, faRemove } from '@fortawesome/free-solid-svg-icons';
import {
  TextField
} from '@mui/material';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { classCategories, teacherId, BE_API_URL, durationOptions, dayOfWeekOptions } from '../constants';
import {getClosingDayAt, formatSchoolTime, convertStringToTime, notifyMessage } from '../common';

const defaultFormData = {
  category: null,
  class_type: 'piano',
  class_name: '',
  maximum_students: 1,
  total_students: 1,
  note: '',
  teacher_id: teacherId,
  opening_day_at: new Date(),
  closing_day_at: getClosingDayAt(),
  school_time: '',
  duration: 60,
  day_of_week: null,
  total_days: 12
}

const ClassForm = (props) => {
  const { isEdit } = props
  const params = useParams()
  const [formData, setFormData] = useState(defaultFormData);
  const [isLoading, setIsLoading] = useState(true)

  const handleChange = (option, e) => {
    setFormData({
      ...formData,
      [e.name]: option,
    });
  };
  const handleChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle form submission here
    console.log('Form submitted:', formData);
    let jsonData = formData
    jsonData.category_id = formData.category.value
    jsonData.duration = formData.duration.value
    jsonData.day_of_week = formData.day_of_week.value
    jsonData.total_days = formData.category.total_days || formData.total_days
    if (formData.school_time) {
      let schoolTime = formData.school_time.split(':')
      schoolTime = schoolTime[0] + ':' + schoolTime[1]
      jsonData.school_time = schoolTime
    }
    handleCallApi(jsonData)
  };

  const handleCallApi = (jsonData) => {
    const requestURL = isEdit ? `${BE_API_URL}/api/v1/classes/${params.id}` : `${BE_API_URL}/api/v1/classes`;
    fetch(requestURL, {
      method: isEdit ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
      .then(response => response.json())
      .then(data => {
        if (!isEdit) {
          setFormData(defaultFormData)
        }
        notifyMessage('success', 'Class ' + (isEdit ? 'updated' : 'created') + ' successfully!')
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        notifyMessage('error', 'Class ' + (isEdit ? 'updated' : 'created') + ' failed!')
      });
  }
  const getClassDetail = (id) => {
    fetch(`${BE_API_URL}/api/v1/classes/${id}`)
      .then(response => response.json())
      .then(dt => {
        let data = dt[0]
        data.category = classCategories.find(cate => cate.value == data.category_id)
        data.duration = durationOptions.find(option => option.value == data.duration)
        data.day_of_week = dayOfWeekOptions.find(option => option.value == data.day_of_week)
        data.opening_day_at = new Date(data.opening_day_at)
        data.closing_day_at = new Date(data.closing_day_at)
        setFormData(data)
        setIsLoading(false)
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }
  const deleteWithConfirmation = () => {
    if (window.confirm('Are you sure you want to delete this class?')) {
      const id = params.id
      fetch(`${BE_API_URL}/api/v1/classes/${id}`, {
        method: 'DELETE',
      })
        .then(response => response.json())
        .then(data => {
          notifyMessage('success', 'Class deleted successfully!')
          window.location.href = '/class-management'
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          notifyMessage('error', 'Class deleted failed!')
        });
    }
  }
  const handleRemoveStudent = (student) => {
    if (window.confirm(`Are you sure you want to remove ${student.name}?`)) {
      const id = params.id
      fetch(`${BE_API_URL}/api/v1/classes/${id}/students/${student.id}`, {
        method: 'DELETE',
      })
        .then(response => response.json())
        .then(data => {
          notifyMessage('success', 'Student removed successfully!')
          getClassDetail(id)
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          notifyMessage('error', 'Student removed failed!')
        });
    }
  }
  const renderListStudents = () => {
    return (
      <div className="grid gap-4 mt-4 mb-4">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <caption class="text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            <div className='grid grid-cols-2 mb-4'>
              <div>
                <h4>List Students</h4>
              </div>
              <div className='text-right'>
                <Link to={`/classes/${params.id}/students`} className='text-blue-700 font-medium'>
                  <FontAwesomeIcon
                    icon={faPlus}
                  /> {` `} Add Student
                </Link>
              </div>
            </div>
          </caption>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">Name</th>
              <th scope="col" class="px-6 py-3">Phone</th>
              <th scope="col" class="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
          { (formData.students || []).map((item) => (
            <tr key={`class-${item.id}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row" class={`px-6 py-4 font-medium dark:text-white text-black`}>
                {item.name}
              </th>
              <td class="px-6 py-4">
                { item.phone }
              </td>
              <td class="px-6 py-4">
                <Link to={`/students/${item.id}/edit?class_id=${params.id}`} className='text-blue-700 font-medium'>
                  <FontAwesomeIcon
                    icon={faEye}
                  />
                </Link>
                <button
                  type="button"
                  className='text-red-700 font-medium ml-4'
                  onClick={() => handleRemoveStudent(item)}
                >
                  <FontAwesomeIcon
                    icon={faRemove}
                  />
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    )
  }
  useEffect(() => {
    if (isEdit && isLoading) {
      const { id } = params
      getClassDetail(id)
    }
  })
  return (
    <div>
      <ToastContainer />
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          { isEdit ? 'Edit Class' : 'Create Class' }
        </h1>
      </div>
      <div>
        <div className="grid gap-4 p-4">
          <div>
            <div className='grid gap-4 grid-cols-2'>
              <div>
                <Link to='/class-management' className='text-blue-700 font-medium'>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                  />{` `} Back to list
                </Link>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="category_id" className="block text-sm font-medium text-gray-600">
                  Category
                </label>
                <Select name='category' placeholder="Choose Category" value={formData.category} options={classCategories} onChange={handleChange} />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Name
                </label>
                <input name='class_name' onChange={handleChangeInput} value={formData.class_name} type="text" placeholder="Enter Class Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
              {/* <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Maximum students
                </label>
                <input name='maximum_students' onChange={handleChangeInput} value={formData.maximum_students} type="number" step={1}  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div> */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Day Of Week
                </label>
                <Select placeholder="Choose Day" value={formData.day_of_week} name='day_of_week' options={dayOfWeekOptions} onChange={handleChange} />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Duration
                </label>
                <Select placeholder="Choose Duration" value={formData.duration} name='duration' options={durationOptions} onChange={handleChange} />
                {/* <input name='duration' onChange={handleChangeInput} value={formData.duration} type="number" step={1}  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required /> */}
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  School Time
                </label>
                <DesktopTimePicker
                  name='school_time'
                  value={convertStringToTime(formData.school_time)}
                  onChange={(time) => setFormData({ ...formData, school_time: formatSchoolTime(time) })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Opening Day At
                </label>
                <DatePicker
                  selected={formData.opening_day_at}
                  onChange={(date) => setFormData({ ...formData, opening_day_at: date })}
                  dateFormat="MM/dd/yyyy"  // Customize the date format if needed
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Closing Day At
                </label>
                <DatePicker
                  selected={formData.closing_day_at}
                  onChange={(date) => setFormData({ ...formData, closing_day_at: date })}
                  dateFormat="MM/dd/yyyy"  // Customize the date format if needed
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div> */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Note
                </label>
                <textarea
                  name='note'
                  className="w-full bg-gray-50 p-4 h-40 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your note here."
                  value={formData.note}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  { isEdit ? 'Update Class' : 'Create Class' }
                </button>
                { isEdit && (
                  <button
                    onClick={deleteWithConfirmation}
                    type="button"
                    className="ml-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                    />
                  </button>
                )}
              </div>
            </form>
            <hr className='mt-4' />
            { isEdit && renderListStudents() }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassForm;
