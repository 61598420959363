import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Select from 'react-select'
import { classCategories, dayOfWeekOptions } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faFilter, faRemove } from '@fortawesome/free-solid-svg-icons';
import { BE_API_URL } from '../constants';
import { getDurationOptions } from '../common';

const defaultFilterFormData = {
  category: null,
  day_of_week: null,
  search_text: '',
}
const ClassManagement= () => {
  const [classes, setClasses] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filterFormData, setFilterFormData] = useState(defaultFilterFormData);

  const handleChange = (option, e) => {
    setFilterFormData({
      ...filterFormData,
      [e.name]: option,
    });
  };

  const getAllClasses = () => {
    fetch(`${BE_API_URL}/api/v1/get-classes`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setClasses(data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleSubmitFilter = () => {
    const { category, day_of_week, search_text } = filterFormData;
    const url = new URL(`${BE_API_URL}/api/v1/get-classes`);
    if (category) {
      url.searchParams.append('category', category.value);
    }
    if (day_of_week) {
      url.searchParams.append('day_of_week', day_of_week.value);
    }
    if (search_text) {
      url.searchParams.append('search_text', search_text);
    }
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setClasses(data);
      })
      .catch(error => {
        console.error(error);
      });
  }
  const handleRemoveFilter = () => {
    setFilterFormData(defaultFilterFormData);
    getAllClasses();
  }

  useEffect(() => {
    if (firstLoad) {
      getAllClasses();
      setFirstLoad(false);
    }
  })
  return (
    <div>
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Class Management
        </h1>
      </div>
      <div>
        <div className="grid gap-4">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <caption class="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
              <div>
                <Link to='/classes/new' className='text-blue-700 font-medium'>
                  <FontAwesomeIcon
                    icon={faPlus}
                  />{` `} Add New Class
                </Link>
              </div>
              <div className='pl4 p4-4'>
                <Select name='category' className='mt-4' placeholder="Filter by Category" onChange={handleChange} options={classCategories} />
                <Select name='day_of_week' className='mt-4 mb-4' placeholder="Filter by Day of Week" onChange={handleChange} options={dayOfWeekOptions} />
                <input placeholder='Search by name' value={filterFormData.search_text} onChange={e => setFilterFormData({ ...filterFormData, search_text: e.target.value })} name='search_text' className='mt-4' value={filterFormData.search_text} type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                <button
                  onClick={handleSubmitFilter}
                  className={`bg-blue-500 hover:bg-blue-700 mt-4 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                  /> {` `} Apply Filter
                </button>
                <button
                  onClick={handleRemoveFilter}
                  className={`bg-red-500 hover:bg-red-700 mt-4 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
                >
                  <FontAwesomeIcon
                    icon={faRemove}
                  /> {` `} Reset
                </button>
              </div>
            </caption>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">Name</th>
                <th scope="col" class="px-6 py-3">Time</th>
                {/* <th scope="col" class="px-6 py-3">Duration</th> */}
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              { classes.map((item) => (
                <tr key={`class-${item.id}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class={`px-6 py-4 font-medium dark:text-white text-black`}>
                    {item.class_name}
                  </th>
                  <td class="px-6 py-4">
                    { item.school_time }
                  </td>
                  {/* <td class="px-6 py-4">
                    {getDurationOptions(item.duration).label}
                  </td> */}
                  <td class="px-6 py-4">
                    <Link to={`/classes/${item.id}`} className='text-blue-700 font-medium'>
                      <FontAwesomeIcon
                        icon={faEye}
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClassManagement;
