import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BE_API_URL } from '../constants';

const defaultFormData = {
  email: '', password: '', rememberMe: false
}

const Login = (props) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  const handleChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCallApi(formData)
  };

  const clearSession = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('current_user');
  }

  const handleCallApi = (jsonData) => {
    const requestURL = `${BE_API_URL}/api/v1/userSignIn`;
    fetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
      .then(response => response.json())
      .then(data => {
        if (data.data && data.data.session) {
          var userSession = data.data.session;
          if (userSession) {
            localStorage.setItem('access_token', userSession.access_token);
            localStorage.setItem('refresh_token', userSession.refresh_token);
            localStorage.setItem('current_user', JSON.stringify(data.data.user));
            setError(null);
            window.location.href = '/'
          } else {
            clearSession()
            setError("Wrong email or password!");
          }
        } else {
          clearSession()
          setError("Wrong email or password!");
        }
      })
      .catch(error => {
        clearSession()
        setError(error.message);
        console.error(error);
      });
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (accessToken && refreshToken) {
      window.location.href = '/'
    }
  }, [navigate])

  const renderError = (error) => {
    return (
      <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
        <span class="font-medium">Errors: </span> {error}
      </div>
    )
  }

  return (
    <div>
      <div className='w-full text-center p-4 mt-20'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Welcome to Ginger App
        </h1>
      </div>
      <div className="p-4">
        <div className="grid gap-4">
          <form onSubmit={handleSubmit} className='w-full'>
            <div className="mb-4 w-full">
              <label className="block text-md font-medium text-gray-700">
                Email Address
              </label>
              <input onChange={handleChangeInput} name='email' value={formData.email} type="email" placeholder="Enter Email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-4 w-full">
              <label className="block text-md font-medium text-gray-700">
                Password
              </label>
              <input onChange={handleChangeInput} name='password' value={formData.password} type="password" placeholder="Enter Password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            { error && renderError(error) }
            <div className="mt-4 text-center">
              <button
                type="submit"
                className="px-5 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
