import React, { useState, useEffect } from 'react';
import Timetable from 'react-timetable-events'
import { toDayAlias, generateColor } from '../common';
import { BE_API_URL, dayOfWeekOptions } from '../constants';
const { format } = require('date-fns');

const Calendar= () => {
  const [firstTime, setFirstTime] = useState(true);
  const [day, setDay] = useState(toDayAlias());
  const today = new Date();
  const defaultEvents = {
    [day]: [
      {
        id: 1,
        name: "Custom Event 1",
        type: "custom",
        startTime: new Date("2018-02-23T11:30:00"),
        endTime: new Date("2018-02-23T13:30:00"),
      },
    ]
  }
  const [events, setEvents] = useState(defaultEvents);
  const getEvents = () => {
    const url = new URL(`${BE_API_URL}/api/v1/get-classes`);
    const dayOfWeek = dayOfWeekOptions.filter((dow) => dow.label.toLowerCase() === day)[0].value;
    if (dayOfWeek) {
      url.searchParams.append('day_of_week', dayOfWeek);
    }
    fetch(url)
      .then(response => response.json())
      .then(data => {
        setFirstTime(false);
        const classAsEvents = data.map((c) => {
          const today = new Date()
          const startTime = `${today.getFullYear()-1}/${today.getMonth() + 1}/${today.getDate()} ${c.school_time}`
          let endTime = `${today.getFullYear()-1}/${today.getMonth() + 1}/${today.getDate()} ${c.school_time}`
          endTime = new Date(endTime).getTime() + c.duration * 60 * 1000
          return {
            id: c.id,
            name: c.class_name,
            type: 'custom',
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            color: generateColor(c.class_name)
          }
        })
        setFirstTime(false);
        setEvents({ ...defaultEvents, [day]: classAsEvents });
      })
      .catch(error => {
        console.error(error);
      });
  }
  useEffect(() => {
    if (firstTime) {
      getEvents();
    }
  })
  const handleClickEvent = (event) => {
    console.log(event)
  }
  const renderEvent = ({
    event,
    defaultAttributes,
    classNames,
  }) => {
    defaultAttributes.className += ` p-4 custom-event-${event.type}`
    defaultAttributes.style.backgroundColor = event.color
    return (
      <div onClick={() => handleClickEvent(event)} {...defaultAttributes} title={event.name} key={event.id}>
        <span className={classNames.event_info + ' font-bold'}>{event.name}</span>
        <span className={classNames.event_info}>
          {format(event.startTime, "HH:mm")} - {format(event.endTime, "HH:mm")}
        </span>
      </div>
    );
  }
  return (
    <div>
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Calendar
        </h1>
        <p className='text-sm'>
          (Today is {format(today, 'yyyy-MM-dd')})
        </p>
      </div>
      <div>
        <div className="w-full mt-4">
          <Timetable 
            bodyAttributes={{
              className: 'styles-module__my_custom_day_body__JnVZR'
            }}
            headerAttributes={{
              className: 'styles-module__my_custom_day_header__ah3Tn'
            }}
            events={events}
            style={{ height: '82vh' }}
            renderEvent={renderEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
