import React, { useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { BE_API_URL } from '../constants';
import { notifyMessage } from '../common';

const NewClassStudent= () => {
  const params = useParams()
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const handleSearch = () => {
    fetch(`${BE_API_URL}/api/v1/search-students?email=${search}&phone=${search}`)
      .then(response => response.json())
      .then(dt => {
        setStudents(dt.students);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }
  const handleOnchangeCheckbox = (studentId) => {
    // Check if the studentId is already in the selectedStudents array
    if (selectedStudents.includes(studentId)) {
      // If it's already selected, remove it from the array
      setSelectedStudents((prevSelected) => prevSelected.filter((id) => id !== studentId));
    } else {
      // If it's not selected, add it to the array
      setSelectedStudents((prevSelected) => [...prevSelected, studentId]);
    }
  };
  const handleAddSelectedStudents = () => {
    fetch(`${BE_API_URL}/api/v1/classes/${params.id}/students`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({studentIds: selectedStudents, classId: params.id})
    })
    .then(response => response.json())
    .then(dt => {
      if (dt && dt.status === false) {
        notifyMessage('error', dt.message)
      } else {
        notifyMessage('success', 'Students added successfully!')
      }
    })
    .catch(error => {
      // Handle any errors here
      console.error(error);
      notifyMessage('error', error)
    });
  }
  return (
    <div>
      <ToastContainer />
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Class's students
        </h1>
      </div>
      <div>
        <div className="p-4">
          <div className='grid gap-4 grid-cols-2'>
            <div>
              <Link to={`/classes/${params.id}`} className='text-blue-700 font-medium'>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                />{` `} Back to class
              </Link>
            </div>
          </div>
          <div className='mt-4'>
            <input value={search} onChange={e => setSearch(e.target.value)} type="text" placeholder="Search student by phone or email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
            <button
              onClick={handleSearch}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-4"
            >
              <FontAwesomeIcon
                icon={faSearch}
              /> Search
            </button>
            <table className="mt-4 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class=""></th>
                  <th scope="col" class="px-6 py-3">Name</th>
                  <th scope="col" class="px-6 py-3">Phone</th>
                  <th scope="col" class="px-6 py-3">Email</th>
                </tr>
              </thead>
              <tbody>
              { students.map((item) => (
                <tr key={`student-${item.id}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="">
                    <input  value={item.id}
                      checked={selectedStudents.includes(item.id)}
                      onChange={() => handleOnchangeCheckbox(item.id)} type='checkbox'
                    />
                  </td>
                  <th scope="row" class={`px-6 py-4 font-medium dark:text-white text-black`}>
                    {item.name}
                  </th>
                  <td class="px-6 py-4">
                    { item.phone }
                  </td>
                  <td class="px-6 py-4 break-all">
                    {item.email}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
            { students.length > 0 && (
              <div className="mt-4">
                <button
                  onClick={handleAddSelectedStudents}
                  type="submit"
                  className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default  NewClassStudent;
