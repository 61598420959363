import React from 'react';

const StudentManagement= () => {
  return (
    <div>
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Student Management
        </h1>
      </div>
      <div>
        <div className="grid grid-cols-2 gap-4">
          
        </div>
      </div>
    </div>
  );
};

export default StudentManagement;
