import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { durationOptions } from './constants';

export const notifyMessage = (messageType = 'success', message) => {
  if (messageType === 'success') {
    toast.success(message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else if (messageType === 'error') {
    toast.error(message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export const getDaysInMonth = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  // Get the first day of the month
  const firstDay = new Date(year, month, 1);

  // Get the last day of the month
  const lastDay = new Date(year, month + 1, 0);

  // Generate an array of days
  const daysArray = [];
  for (let i = firstDay.getDate(); i <= lastDay.getDate(); i++) {
    daysArray.push(i);
  }

  return daysArray;
}
export const getDaysInWeek = () => {
  const currentDate = new Date();
  const day = currentDate.getDay();
  const daysArray = [];
  for (let i = 0; i < 7; i++) {
    daysArray.push(currentDate.getDate() - day + i);
  }
  return daysArray;
}
export const getFirstDayOfWeek = () => {
  const currentDate = new Date();
  const day = currentDate.getDay();
  return day;
}

export const generateRandomColors = (numColors) => {
  const randomColors = [];
  for (let i = 0; i < numColors; i++) {
    const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
    randomColors.push(color);
  }
  return randomColors;
};

export const generateColor = (text) => {
  const hashedValue = CryptoJS.MD5(text).toString();
  const color = `#${hashedValue.substring(0, 6)}`;
  return color;
};

export const getLast6Months = () => {
  const today = new Date();
  const monthsArray = [];

  for (let i = 0; i < 6; i++) {
    const year = today.getFullYear();
    const month = (today.getMonth() - i + 12) % 12; // Ensure we wrap around to the previous year
    const formattedMonth = month + 1; // Adding 1 to make months 1-based (January is 1, February is 2, etc.)
    
    monthsArray.push({ year, month: formattedMonth });
  }

  return monthsArray.reverse(); // Reverse the array to get the chronological order
};

export const getAllMonthsInYear = (today = null) => {
  if (!today) {
    today = new Date();
  }
  const currentYear = today.getFullYear();
  const monthsArray = [];

  for (let month = 0; month < 12; month++) {
    monthsArray.push({ year: currentYear, month: month + 1 });
  }

  return monthsArray;
};

export const getClosingDayAt = (today = null) => {
  if (!today) {
    today = new Date();
  }
  const closingDay = new Date(today.getFullYear(), today.getMonth() + 3, 0);
  return closingDay;
}

export const formatSchoolTime = function (value) {
  let newValue = dayjs(value)
  return newValue.format('HH:mm:a')
}

export const convertStringToTime = function (value) {
  if (!value) {
    return null
  }
  const st = value.split(':')
  return dayjs().hour(parseInt(st[0])).minute(parseInt(st[1]))
}
export const getDurationOptions = (value) => {
  return durationOptions.find(option => option.value == value)
}

export const toDayAlias = () => {
  const date = new Date();
  const day = date.getDay();
  switch (day) {
    case 0:
      return "sunday";
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
  }
}