import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { genderOptions, BE_API_URL } from '../constants';
import { notifyMessage } from '../common';

const defaultFormData = {
  name: '',
  email: '',
  phone: '',
  username: '',
  age: '',
  gender: 0,
  address: '',
}
const StudentForm = (props) => {
  const { isEdit } = props
  const params = useParams()
  const [formData, setFormData] = useState(defaultFormData);
  const [firstLoad, setFirstLoad] = useState(true);
  const [prevLink, setPrevLink] = useState('')
  const handleChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }
  const handleChange = (option, e) => {
    setFormData({
      ...formData,
      [e.name]: option,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let jsonData = formData
    jsonData.gender = formData.gender.value
    jsonData.username = formData.email
    console.log(jsonData)
    handleCallApi(jsonData)
  };
  const handleCallApi = (jsonData) => {
    const requestURL = isEdit ? `${BE_API_URL}/api/v1/students/${params.id}` : `${BE_API_URL}/api/v1/students`;
    fetch(requestURL, {
      method: isEdit ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
      .then(response => response.json())
      .then(data => {
        if (!data.status){
          notifyMessage('error', data.error)
          return
        }
        if (!isEdit) {
          setFormData(defaultFormData)
        }
        notifyMessage('success', 'Student ' + (isEdit ? 'updated' : 'created') + ' successfully!')
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        notifyMessage('error', error)
      });
  }
  const getStudentDetail = (id) => {
    fetch(`${BE_API_URL}/api/v1/students/${id}`)
      .then(response => response.json())
      .then(data => {
        if (!data.status){
          notifyMessage('error', data.error)
          return
        }
        data.student.gender = { value: data.student.gender, label: data.student.gender === 0 ? 'Male' : 'Female' }
        setFormData(data.student)
        setFirstLoad(false)
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        notifyMessage('error', error)
      });
  }
  const deleteWithConfirmation = () => {
    if (window.confirm('Are you sure you want to delete this student?')) {
      const id = params.id
      fetch(`${BE_API_URL}/api/v1/students/${id}`, {
        method: 'DELETE',
      })
        .then(response => response.json())
        .then(data => {
          notifyMessage('success', 'Student deleted successfully!')
          window.location.href = '/students'
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          notifyMessage('error', 'Student deleted failed!')
        });
    }
  }
  useEffect(() => {
    if (isEdit && params.id && firstLoad) {
      getStudentDetail(params.id)
    }
    const searchParams = new URLSearchParams(window.location.search)
    const tempPrevLink = searchParams.get('class_id')  ? `/classes/${searchParams.get('class_id')}` : '/students'
    setPrevLink(tempPrevLink)
  })
  return (
    <div>
      <ToastContainer />
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          {isEdit ? 'Edit Student' : 'Add New Student'}
        </h1>
      </div>
      <div>
        <div className="p-4">
          <div className='grid gap-4 grid-cols-2'>
            <div>
              <Link to={prevLink} className='text-blue-700 font-medium'>
                <FontAwesomeIcon
                  icon={faArrowLeft}
                />{` `} Back
              </Link>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">
                Name
              </label>
              <input name='name' onChange={handleChangeInput} value={formData.name} type="text" placeholder="Enter Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-4">
                <label htmlFor="category_id" className="block text-sm font-medium text-gray-600">
                  Gender
                </label>
                <Select required name='gender' placeholder="Choose Gender" value={formData.gender} options={genderOptions} onChange={handleChange} />
              </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">
                Email
              </label>
              <input name='email' onChange={handleChangeInput} value={formData.email} type="email" placeholder="name@email.com" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">
                Phone
              </label>
              <input name='phone' onChange={handleChangeInput} value={formData.phone} type="tel" placeholder="0981234567" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">
                Age
              </label>
              <input min={3} max={100} name='age' onChange={handleChangeInput} value={formData.age} type="number" step={1} placeholder="Enter Age" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">
                Address
              </label>
              <textarea name='address' onChange={handleChangeInput} value={formData.address} placeholder="Enter Address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                { isEdit ? 'Update Student' : 'Create Student' }
              </button>
              { isEdit && (
                  <button
                    onClick={deleteWithConfirmation}
                    type="button"
                    className="ml-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  >
                    Delete
                  </button>
                )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StudentForm;
