import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BE_API_URL } from '../constants';
import {notifyMessage } from '../common';

const defaultFormData = {
  title: 'Test sent notification',
  body: 'This is a test notification'
}

const TestFcm= () => {
  const [formData, setFormData] = useState(defaultFormData);
  const handleSubmit = (e) => {
    e.preventDefault();
    const requestURL = `${BE_API_URL}/api/v1/notifications/pushAllDevices`;
    fetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.info('Success!')
        // notifyMessage('success','Sent FCM successfully!')
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
        notifyMessage('error','Sent FCM failed!')
      });
  };
  const handleChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <div>
      <ToastContainer />
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Test Fcm
        </h1>
      </div>
      <div>
        <div className="grid gap-4 p-4">
          <div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Title
                </label>
                <input name='title' onChange={handleChangeInput} value={formData.title} type="text" placeholder="Enter Class Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">
                  Body
                </label>
                <textarea
                  name='body'
                  className="w-full bg-gray-50 p-4 h-40 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your content here."
                  value={formData.body}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Sent
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestFcm;
