import React, { useState, useEffect } from 'react';
import { FadeLoader } from 'react-spinners';

import { BE_API_URL } from '../constants';
const override ={
  display: "block",
  margin: "50% auto",
};

const GoldPrice= () => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [priceAtDateTime, setPriceAtDateTime] = useState(null);
  const [priceTable, setPriceTable] = useState([]);

  const fetchGoldPrice = async () => {
    const response = await fetch(`${BE_API_URL}/api/gold/pnj`)
    const rawData = await response.json();
    if (rawData) {
      setFirstLoad(false)
      const data = rawData.data
      setPriceAtDateTime(data.priceAtDateTime)
      setPriceTable(data.tableData)
    }
  }
  useEffect(() => {
    if (firstLoad) {
      fetchGoldPrice()
    }
  })
  return (
    <div>
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Gold Price
        </h1>
      </div>
      <div>
        { firstLoad && (<div className='w-full mx-auto text-center'>
          <FadeLoader
          color='#f44e3b'
          loading={firstLoad}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        </div>) }
        { !firstLoad && (
          <div className='w-full text-center'>
            <h4 className="text-gray-900 dark:text-white">
              Last Price: {priceAtDateTime}
            </h4>
            <table className="w-full mt-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">Gold Type</th>
                  <th scope="col" class="px-6 py-3">Purchase Price</th>
                  <th scope="col" class="px-6 py-3">Selling Price</th>
                </tr>
              </thead>
              <tbody>
              { priceTable.map((item) => (
                  <tr key={`class-${item.id}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class={`px-6 py-4 font-medium dark:text-white text-black`}>
                      {item.goldType}
                    </th>
                    <td class="px-6 py-4">
                      { item.purchasePrice }
                    </td>
                    <td class="px-6 py-4">
                      {item.sellingPrice}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoldPrice;
