import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { PacmanLoader, FadeLoader } from 'react-spinners';
import Select from 'react-select'
import { 
  getDaysInMonth,
  getDaysInWeek,
  getLast6Months,
  getAllMonthsInYear,
  generateColor
} from '../common'
import { BE_API_URL } from '../constants';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
const override ={
  display: "block",
  margin: "50% auto",
};

const MoneyAnalystic = () => {
  // const today = new Date();
  // const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
  // const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6);
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth(), 1);
  const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [dates, setDates] = useState(getDaysInWeek())
  const data = {  
    labels: dates,
    datasets: [
    ],
  };
  const [dataByDay, setDataByDay] = useState(data)
  const [incomeDataByDay, setIncomeDataByDay] = useState(data)
  const [isLoading, setIsLoading] = useState(true)
  const [key, setKey] = useState(0)
  const [totalOutcomeAmount, setTotalOutcomeAmount] = useState(0)
  const [totalIncomeAmount, setTotalIncomeAmount] = useState(0)
  const [totalAllOutcomeAmount, setTotalAllOutcomeAmount] = useState(0)
  const [totalAllIncomeAmount, setTotalAllIncomeAmount] = useState(0)
  const [pieChartData, setPieChart] = useState(data)
  const [isSpecialChart, setIsSpecialChart] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [userOptions, setUserOptions] = useState([]);

  const selectThisWeek = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const end = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6);
    setDates(getDaysInWeek())
    setStartDate(start);
    setEndDate(end);
    setLoading(true)
  }
  const handleSelectUserChange = (option) => {
    setSelectedUserOption(option);
  }
  const selectThisMonth = () => {
    setIsSpecialChart(false)
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setDates(getDaysInMonth())
    setStartDate(start);
    setEndDate(end);
    setLoading(true)
  }

  const selectLast6Months = () => {
    setIsSpecialChart(true)
    const last6months = getLast6Months()
    const displayMonths = last6months.map((item) => `${item.year}-${item.month < 10 ? '0' + item.month : item.month}`)
    setDates(displayMonths)
    const start = new Date(last6months[0].year, last6months[0].month - 1, 1);
    const end = new Date(last6months[last6months.length - 1].year, last6months[last6months.length - 1].month, 0);
    setStartDate(start);
    setEndDate(end);
  }

  const selectThisYear = () => {
    setIsSpecialChart(true)
    const allMonthsInYear = getAllMonthsInYear()
    const displayMonths = allMonthsInYear.map((item) => `${item.year}-${item.month < 10 ? '0' + item.month : item.month}`)
    setDates(displayMonths)
    const start = new Date(allMonthsInYear[0].year, allMonthsInYear[0].month - 1, 1);
    const end = new Date(allMonthsInYear[allMonthsInYear.length - 1].year, allMonthsInYear[allMonthsInYear.length - 1].month, 0);
    setStartDate(start);
    setEndDate(end);
    setLoading(true)
  }

  const selectLastMonth = () => {
    // setIsSpecialChart(true)
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const end = new Date(today.getFullYear(), today.getMonth(), 0);
    setDates(getDaysInMonth())
    setStartDate(start);
    setEndDate(end);
    setLoading(true)
  }

  const selectLastYear = () => {
    setIsSpecialChart(true)
    const today = new Date();
    const start = new Date(today.getFullYear() - 1, 0, 1);
    const end = new Date(today.getFullYear(), 0, 0);
    const allMonthsInYear = getAllMonthsInYear(start)
    const displayMonths = allMonthsInYear.map((item) => `${item.year}-${item.month < 10 ? '0' + item.month : item.month}`)
    setDates(displayMonths)
    setStartDate(start);
    setEndDate(end);
    setLoading(true)
  }

  const getChartData = (chartType = 'outcome') => {
    let requestApi = ''
    switch (chartType) {
      case 'outcome':
        requestApi = `${BE_API_URL}/api/money-lover/get-transaction-analystic-outcome-by-date-range?startDate=${startDate}&endDate=${endDate}&user=${selectedUserOption?.value}`
        break
      case 'income':
        requestApi = `${BE_API_URL}/api/money-lover/get-transaction-analystic-income-by-date-range?startDate=${startDate}&endDate=${endDate}&user=${selectedUserOption?.value}`
        break
      default:
        break
    }
    let newDataSet = []
    fetch(requestApi, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      const totalAmount = data.reduce((total, item) => total + parseFloat(item.total_amount), 0)
      userOptions.map((user) => {
        const color = generateColor(user.label)
        const backgroundColor = color
        const borderColor = color
        const userTransactions = data.filter((item) => item.user_uuid == user.value)
        let userData = []
        dates.map((date) => {
          const transaction = userTransactions.find((item) => item.day_of_month == date)
          if (transaction) {
            userData.push(transaction.total_amount || 0)
          } else {
            userData.push(0)
          }
        })
        newDataSet.push({
          label: user.label,
          data: userData,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        })
      })
      switch (chartType) {
        case 'outcome':
          setDataByDay({ ...dataByDay, datasets: newDataSet, labels: dates })
          setTotalOutcomeAmount(totalAmount)
          break
        case 'income':
          setIncomeDataByDay({ ...incomeDataByDay, datasets: newDataSet, labels: dates })
          setTotalIncomeAmount(totalAmount)
          break
        default:
      }
    }).catch(error => {
    });
  }
  const getOutcomePieChartDataByDateRange = () => {
    fetch(`${BE_API_URL}/api/money-lover/get-transaction-analystic-income-pie-chart-data-by-date-range?startDate=${startDate}&endDate=${endDate}&user=${selectedUserOption?.value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      const labels = data.map((item) => item.category)
      const pieDataAmount = data.map((item) => item.total_amount)
      const colors = labels.map((label) => generateColor(label))
      const pieData = {
        labels: labels,
        datasets: [
          {
            data: pieDataAmount,
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
          },
        ],
      };
      setPieChart(pieData)
      setLoading(false)
    })
  }

  const getAnalysticByMonths = (chartType = 'outcome') => {
    let newDataSet = []
    let requestApi = ''
    switch (chartType) {
      case 'outcome':
        requestApi = `${BE_API_URL}/api/money-lover/get-transaction-analystic-outcome-by-month-range?startDate=${startDate}&endDate=${endDate}&user=${selectedUserOption?.value}`
        break
      case 'income':
        requestApi = `${BE_API_URL}/api/money-lover/get-transaction-analystic-income-by-month-range?startDate=${startDate}&endDate=${endDate}&user=${selectedUserOption?.value}`
        break
      default:
    }
    fetch(requestApi, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      const totalAmount = data.reduce((total, item) => total + parseFloat(item.total_amount), 0)
      userOptions.map((user) => {
        const color = generateColor(user.label)
        const backgroundColor = color
        const borderColor = color
        const userTransactions = data.filter((item) => item.user_uuid == user.value)
        let userData = []
        dates.map((month) => {
          const transaction = userTransactions.find((item) => item.month == month)
          if (transaction) {
            userData.push(transaction.total_amount || 0)
          } else {
            userData.push(0)
          }
        })
        newDataSet.push({
          label: user.label,
          data: userData,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        })
      })
      switch (chartType) {
        case 'outcome':
          setDataByDay({ ...dataByDay, datasets: newDataSet, labels: dates })
          setTotalOutcomeAmount(totalAmount)
          break
        case 'income':
          setIncomeDataByDay({ ...incomeDataByDay, datasets: newDataSet, labels: dates })
          setTotalIncomeAmount(totalAmount)
          break
        default:
      }
    })
  }

  const getTotalOutcome = () => {
    fetch(`${BE_API_URL}/api/money-lover/get-total-outcome?startDate=${startDate}&endDate=${endDate}`)
      .then(response => response.json())
      .then(data => {
        const total = data.total[0].total_amount || 0 
        setTotalAllOutcomeAmount(parseInt(total));
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }

  const getTotalIncome = () => {
    fetch(`${BE_API_URL}/api/money-lover/get-total-income?startDate=${startDate}&endDate=${endDate}`)
      .then(response => response.json())
      .then(data => {
        const total = data.total[0].total_amount || 0
        setTotalAllIncomeAmount(parseInt(total));
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }

  const getUsersInGroup = (filter_user_uuid) => {
    fetch(`${BE_API_URL}/api/v1/getUsersInGroup`)
      .then(response => response.json())
      .then(data => {
        let opts = []
        data.users.map((u) => {
          opts.push({value: u.id, label: u.name})
        });
        // opts.push({ value: undefined, label: 'All'})
        setUserOptions(opts)
        opts = opts.filter((u) => u.value === filter_user_uuid);
        setSelectedUserOption(opts[0])
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }

  useEffect(() => {
    if (isLoading) {
      const user = JSON.parse(localStorage.getItem('current_user'))
      getUsersInGroup(user.id)
      setIsLoading(false)
    } else {
      setKey(key + 1)
      if (isSpecialChart) {
        getAnalysticByMonths()
        getAnalysticByMonths('income')
      } else {
        getChartData()
        getChartData('income')
      }
      getOutcomePieChartDataByDateRange()
      getTotalOutcome();
      getTotalIncome()
    }
  }, [isLoading, startDate, endDate, isSpecialChart, selectedUserOption])
  return (
    <div>
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Money Analystic
        </h1>
      </div>
      <div>
        <div className="flex justify-center mt-4">
          {/* <button
            onClick={selectThisWeek}
            className={`bg-purple-500 hover:bg-purple-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
          >
            This Week
          </button> */}
          <button
            onClick={selectThisMonth}
            className={`bg-blue-500 hover:bg-blue-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
          >
            This Month
          </button>
          <button
            onClick={selectLastMonth}
            className={`bg-blue-500 hover:bg-blue-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
          >
            Last Month
          </button>
          {/* <button
            onClick={selectLast6Months}
            className={`bg-green-500 hover:bg-green-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
          >
            Last 6 Months
          </button> */}
          <button
            onClick={selectThisYear}
            className={`bg-red-500 hover:bg-red-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
          >
            This Year
          </button>
          <button
            onClick={selectLastYear}
            className={`bg-yellow-500 hover:bg-yellow-700 mr-2 text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500`}
          >
            Last Year
          </button>
        </div>
        { loading && (<FadeLoader
          color='#f44e3b'
          loading={loading}
          cssOverride={override}
          size={40}
          aria-label="Loading Spinner"
          data-testid="loader"
        />) }
        { !loading && (
          <div className="">
            <div className='mb-4 mt-4 text-center'>
              <h2 className="text-2xl p-2 font-bold text-gray-900 dark:text-white">
                <span className="text-red-500">{totalAllOutcomeAmount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}</span>
                &nbsp;&nbsp;&nbsp;
                <span className="text-green-500">{totalAllIncomeAmount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}</span>
              </h2>
            </div>
            <div className="flex justify-center mt-4">
              <Select placeholder="Choose User" value={selectedUserOption} options={userOptions} onChange={handleSelectUserChange} />
            </div>
            <div className='mb-4'>
              <h4 className="text-normal p-2 font-bold text-gray-900 dark:text-white">
                Outcome <span className="text-red-500">{totalOutcomeAmount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}</span>
              </h4>
              <Bar key={key} data={dataByDay} options={options} />
              <Pie data={pieChartData} />
            </div>
            <hr />
            <div>
              <h4 className="text-normal p-2 font-bold text-gray-900 dark:text-white">
                Income <span className="text-green-500">{totalIncomeAmount.toLocaleString('vi-VN', {style: 'currency',currency: 'VND'})}</span>
              </h4>
              <Bar key={key} data={incomeDataByDay} options={options} />
            </div>
            {/* <div>
              <Pie data={pieChartData} />
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default MoneyAnalystic;
