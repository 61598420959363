import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BE_API_URL } from '../constants';

const defaultFormData = {
  name: ''
}

const Profile = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultFormData);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null)

  const handleChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCallApi(formData)
  };

  const handleCallApi = (jsonData) => {
    jsonData.id = currentUser
    const requestURL = `${BE_API_URL}/api/v1/userUpdate`;
    fetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonData)
    })
      .then(response => response.json())
      .then(data => {
      })
      .catch(error => {
        setError(error.message);
        console.error(error);
      });
  }

  const getUserProfile = (filter_user_uuid) => {
    fetch(`${BE_API_URL}/api/v1/getUserProfile?user_uuid=${filter_user_uuid}`)
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          const user = data.user[0]
          setFormData({
            ...formData,
            name: user.name
          });
        }
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  }

  const handlerSignOut = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('current_user');
    window.location.href = '/login'
  }

  const renderError = (error) => {
    return (
      <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
        <span class="font-medium">Errors: </span> {error}
      </div>
    )
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (!accessToken || !refreshToken) {
      window.location.href = '/login'
    } else {
      const user = JSON.parse(localStorage.getItem('current_user'))
      setCurrentUser(user.id)
      getUserProfile(user.id)
    }
  }, [navigate])

  return (
    <div>
      <div className='w-full text-center'>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          My Profile
        </h1>
      </div>
      <div className="p-4">
        <div className="grid gap-4">
          <form onSubmit={handleSubmit} className='w-full'>
            <div className="mb-4 w-full">
              <label className="block text-md font-medium text-gray-700">
                Name
              </label>
              <input onChange={handleChangeInput} name='name' value={formData.name} type="text" placeholder="Enter your name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>
            { error && renderError(error) }
            <div className="mt-4 text-center">
              <button
                type="submit"
                className="px-5 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Update
              </button>
              <button
                type="button"
                className="ml-10 px-5 py-3 bg-red-500 text-white rounded-md hover:bg-red-600"
                onClick={handlerSignOut}
              >
                Sign Out
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
